import React, { useState, useContext, useRef } from "react"
import i18n from "i18next"
import { Link } from "gatsby"
import CardStandarProfilegHunting from "./CardStandarProfilegHunting"
import { LoadingContext } from "../context/LoadingContext"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import leftArrow from "../../images/servicesHunting/icons/leftArrow.png"
import rigthArrow from "../../images/servicesHunting/icons/rigthArrow.png"

function StandarProfilegHunting() {
  const { windowWidth } = useContext(LoadingContext)
  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef(null)

  const profiles = [
    {
      title: i18n.t("pageServiceHunting.standarProfile.stages.stage1.title"),
      description: i18n.t(
        "pageServiceHunting.standarProfile.stages.stage1.description"
      ),
      barras: [
        {
          nombre: "Management",
          barra: 100,
        },
        {
          nombre: "Leadership",
          barra: 100,
        },
        {
          nombre: "Processes",
          barra: 100,
        },
        {
          nombre: "Technical Skills",
          barra: 26,
        },
        {
          nombre: "Industry Experience",
          barra: 50,
        },
        {
          nombre: "English",
          barra: 75,
        },
        {
          nombre: "Business Knowledge",
          barra: 50,
        },
      ],
    },
    {
      title: i18n.t("pageServiceHunting.standarProfile.stages.stage2.title"),
      description: i18n.t(
        "pageServiceHunting.standarProfile.stages.stage2.description"
      ),
      barras: [
        {
          nombre: "Communication",
          barra: 100,
        },
        {
          nombre: "Leadership",
          barra: 70,
        },
        {
          nombre: "Processes",
          barra: 100,
        },
        {
          nombre: "Technical Skills",
          barra: 45,
        },
        {
          nombre: "Industry Experience",
          barra: 100,
        },
        {
          nombre: "English",
          barra: 90,
        },
        {
          nombre: "Business Knowledge",
          barra: 100,
        },
      ],
    },
    {
      title: i18n.t("pageServiceHunting.standarProfile.stages.stage3.title"),
      description: i18n.t(
        "pageServiceHunting.standarProfile.stages.stage3.description"
      ),
      barras: [
        {
          nombre: "Processes",
          barra: 100,
        },
        {
          nombre: "Communication",
          barra: 100,
        },
        {
          nombre: "Leadership",
          barra: 25,
        },
        {
          nombre: "Technical Skills",
          barra: 50,
        },
        {
          nombre: "Industry Experience",
          barra: 50,
        },
        {
          nombre: "English",
          barra: 90,
        },
        {
          nombre: "Business Knowledge",
          barra: 50,
        },
      ],
    },
    {
      title: i18n.t("pageServiceHunting.standarProfile.stages.stage4.title"),
      description: i18n.t(
        "pageServiceHunting.standarProfile.stages.stage4.description"
      ),
      barras: [
        {
          nombre: "Abstraction",
          barra: 100,
        },
        {
          nombre: "Leadership",
          barra: 70,
        },
        {
          nombre: "Communication",
          barra: 70,
        },
        {
          nombre: "Technical Skills",
          barra: 100,
        },
        {
          nombre: "Industry Experience",
          barra: 70,
        },
        {
          nombre: "English",
          barra: 90,
        },
        {
          nombre: "Business Knowledge",
          barra: 70,
        },
      ],
    },
  ]

  const settingsMobile = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const goToSlide = index => {
    setCurrentIndex(index)
    if (carouselRef.current) {
      if (index < currentIndex) {
        carouselRef.current.slickPrev()
      } else if (index > currentIndex) {
        carouselRef.current.slickNext()
      }
    }
  }
  return (
    <section className="whyusdiv p-5 tablet:p-20 text-left bigdesktop:px-32">
      <div className="flex flex-col">
        <h4 className="text-blue-100 font-avenir-black text-12">
          {i18n.t("pageServiceHunting.standarProfile.stitle")}
        </h4>
        <div className="flex justify-between">
          <p className="font-bold mt-2 mb-4 font-avenir-black text-gray-600 text-48 leading-tight">
            {i18n.t("pageServiceHunting.standarProfile.title")}
          </p>
          <span className="hidden tablet:block">
            <Link to="/contact-us">
              <button className="text-blue-100 border-2 rounded-lg font-sofiaPro-bold px-8 py-2 border-blue-100">
                {i18n.t("pageServiceHunting.standarProfile.button")}
              </button>
            </Link>
          </span>
        </div>

        {windowWidth > 500 ? (
          <div className="tablet:flex gap-4">
            <CardStandarProfilegHunting profiles={profiles} />
          </div>
        ) : (
          <Slider
            ref={carouselRef}
            {...settingsMobile}
            className=" "
          >
            {profiles.map((item, i) => (
              <div
                key={i}
                className="flex-1 bg-white p-8 flex flex-col gap-4 rounded-sm"
              >
                <h3>{item.title}</h3>
                <p className="text-14 text-gray-500">{item.description}</p>
                <div className="mt-3">
                  {item.barras.map((bar, index) => (
                    <div key={index * 3} className="flex flex-col rounded-lg">
                      <div className="w-full h-2 bg-gray-50 rounded-lg">
                        <div
                          className="h-2 bg-blue-200 rounded-lg"
                          style={{ width: `${bar.barra}%` }}
                        ></div>
                      </div>
                      <span className="mb-3">{bar.nombre}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Slider>
        )}
         <div className="tablet:hidden flex justify-center gap-16 my-12">
          <img
            src={leftArrow}
            alt="left"
            className="cursor-pointer"
            onClick={() => goToSlide(currentIndex - 1)}
          />
          <img
            src={rigthArrow}
            alt="rigth"
            className="cursor-pointer"
            onClick={() => goToSlide(currentIndex + 1)}
          />
        </div>
        <span className="tablet:hidden w-full">
          <Link to="/contact-us">
            <button className="text-blue-100 w-full mb-8 mt-5 border-2 rounded-lg font-sofiaPro-bold px-8 py-2 border-blue-100 ">
              {i18n.t("pageServiceHunting.standarProfile.button")}
            </button>
          </Link>
        </span>
      </div>
    </section>
  )
}

export default StandarProfilegHunting
