import React, { useState, useRef } from "react"
import i18n from "i18next"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import image1 from "../../images/servicesHunting/benefitsCarrusel/AccompanimentLogistics.png"
import image2 from "../../images/servicesHunting/benefitsCarrusel/ApplicantTrackingSystem.png"
import image3 from "../../images/servicesHunting/benefitsCarrusel/AssessmentCenter.png"
import image4 from "../../images/servicesHunting/benefitsCarrusel/AverageResponse.png"
import image5 from "../../images/servicesHunting/benefitsCarrusel/CandidateMonitoring.png"
import image6 from "../../images/servicesHunting/benefitsCarrusel/Communication.png"
import image7 from "../../images/servicesHunting/benefitsCarrusel/PersonalityTests.png"
import image8 from "../../images/servicesHunting/benefitsCarrusel/ContinuousCVReviews.jpg"
import leftArrow from "../../images/servicesHunting/icons/leftArrow.png"
import rigthArrow from "../../images/servicesHunting/icons/rigthArrow.png"
import xElementWhite from "../../images/servicesHunting/icons/xDobleWhite.png"

function HuntingBenefits() {
  const images = [
    image4,
    image1,
    image7,
    image5,
    image6,
    image3,
    image2,
    image8,
  ]
  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef(null)
  const benefits = [
    {
      title: i18n.t("pageServiceHunting.benefits.stages.stage1.title"),
      desc: i18n.t("pageServiceHunting.benefits.stages.stage1.desc1"),
    },
    {
      title: i18n.t("pageServiceHunting.benefits.stages.stage2.title"),
      desc: i18n.t("pageServiceHunting.benefits.stages.stage2.desc2"),
    },
    {
      title: i18n.t("pageServiceHunting.benefits.stages.stage3.title"),
      desc: i18n.t("pageServiceHunting.benefits.stages.stage3.desc3"),
    },
    {
      title: i18n.t("pageServiceHunting.benefits.stages.stage4.title"),
      desc: i18n.t("pageServiceHunting.benefits.stages.stage4.desc4"),
    },
    {
      title: i18n.t("pageServiceHunting.benefits.stages.stage5.title"),
      desc: i18n.t("pageServiceHunting.benefits.stages.stage5.desc5"),
    },
    {
      title: i18n.t("pageServiceHunting.benefits.stages.stage6.title"),
      desc: i18n.t("pageServiceHunting.benefits.stages.stage6.desc6"),
    },
    {
      title: i18n.t("pageServiceHunting.benefits.stages.stage7.title"),
      desc: i18n.t("pageServiceHunting.benefits.stages.stage7.desc7"),
    },
    {
      title: i18n.t("pageServiceHunting.benefits.stages.stage8.title"),
      desc: i18n.t("pageServiceHunting.benefits.stages.stage8.desc8"),
    },
  ]

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const settingsMobile = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const goToSlide = index => {
    setCurrentIndex(index)
    if (carouselRef.current) {
      if (index < currentIndex) {
        carouselRef.current.slickPrev()
      } else if (index > currentIndex) {
        carouselRef.current.slickNext()
      }
    }
  }

  return (
    <section className="tablet:p-10  mb-6 mt-8 ultradesktop:mr-10 bigdesktop:pl-32 bigdesktop:pr-24">
      <h2 className="text-gray-600 text-40  mb-5 tablet:mb-10 font-avenir-black">
        {i18n.t("pageServiceHunting.benefits.title")}
      </h2>

      <Slider
        {...settings}
        className="hidden tablet:block rounded-sm shadow-md ultradesktop:p-10"
      >
        {benefits.map((item, i) => (
          <div
            key={i}
            className="flex gap-32 bigdesktop:gap-0 px-10 bigdesktop:pl-24"
          >
            <div className="flex-1 w-full h-full">
              <img
                src={images[i]}
                className="w-full bigdesktop:w-4/5 h-full"
                alt="text-1"
              />
              <img
                className="pl-6 ultradesktop:pl-32"
                src={xElementWhite}
                alt="xWhite"
              />
            </div>

            <div className="flex-1 w-fit flex flex-col items-start gap-8 ultradesktop:mt-16">
              <h3 className="text-36 bigdesktop:text-40 font-avenir-light text-gray-600">
                {item.title}
              </h3>
              <p className="text-24 bigdesktop:text-32 text-left text-gray-500 font-avenir-light ultradesktop:w-11/12">
                {item.desc}
              </p>
              <span>
                <Link to="/contact-us">
                  <button className="bg-blue-100 px-8 py-2 rounded-lg font-sofiaPro-bold text-white">
                    {i18n.t("pageServiceHunting.benefits.button")}
                  </button>
                </Link>
              </span>
            </div>
          </div>
        ))}
      </Slider>

      {/* mobile */}
      <div className="tablet:hidden">
        <Slider
          ref={carouselRef}
          {...settingsMobile}
          className="rounded-sm shadow-xl mx-2 py-10"
        >
          {benefits.map((item, i) => (
            <div
             key={i} 
             className="px-10 py-2">
              <div className="w-full h-full bigdesktop:w-2/6">
                <img src={images[i]} className=" w-full h-full " alt="text-1" />
                <img
                  className="pl-6 ultradesktop:pl-32 w-2/5"
                  src={xElementWhite}
                  alt="xWhite"
                />
              </div>
              <div className="flex flex-col items-start gap-8">
                <h3 className="text-36 text-left font-avenir-light text-gray-600">
                  {item.title}
                </h3>
                <p className="text-24 text-left text-gray-500 font-avenir-light">
                  {item.desc}
                </p>
                <span className="w-full">
                  <Link to="/">
                    <button className="bg-blue-100 w-full px-8 py-2 rounded-lg font-sofiaPro-bold text-white">
                      {i18n.t("pageServiceHunting.benefits.button")}
                    </button>
                  </Link>
                </span>
              </div>
            </div>
          ))}
        </Slider>
        <div className="flex justify-center gap-16 mt-8 mb-16">
          <img
            src={leftArrow}
            alt="left"
            className="cursor-pointer"
            onClick={() => goToSlide(currentIndex - 1)}
          />
          <img
            src={rigthArrow}
            alt="rigth"
            className="cursor-pointer"
            onClick={() => goToSlide(currentIndex + 1)}
          />
        </div>
      </div>
    </section>
  )
}

export default HuntingBenefits
