import React from "react"

function CardStandarProfilegHunting({ profiles }) {
  return (
    <>
      {profiles.map((item, i) => (
        <div
          key={i}
          className="flex-1 bg-white p-8 bigdesktop:p-16 flex flex-col gap-4 rounded-sm"
        >
          <h3 className="bigdesktop:text-24">{item.title}</h3>
          <p className="text-14 bigdesktop:text-18 text-gray-500">{item.description}</p>
          <div className="mt-3">
            {item.barras.map((bar, index) => (
              <div key={index * 3} className="flex flex-col rounded-lg">
                <div className="w-full h-2 bg-gray-50 rounded-lg">
                  <div
                    className="h-2 bg-blue-200 rounded-lg"
                    style={{ width: `${bar.barra}%` }}
                  ></div>
                </div>
                <span className="bigdesktop:text-16 mb-3">{bar.nombre}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  )
}

export default CardStandarProfilegHunting
