import React, {useEffect} from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BannerServicesHunting from '../components/servicesHunting/BannerServicesHunting'
import imagee from "../images/servicesHunting/BannerHunting.png"
import OurServicesHunting from '../components/servicesHunting/OurServicesHunting'
import WhyUsHunting from '../components/servicesHunting/WhyUsHunting'
import PreOperationStage from '../components/servicesHunting/PreOperationStage'
import RequestQuoteHunting from '../components/servicesHunting/RequestQuoteHunting'
import HuntingBenefits from '../components/servicesHunting/HuntingBenefits'
import StandarProfilegHunting from '../components/servicesHunting/StandarProfilegHunting'
import AugmentationHunting from '../components/servicesHunting/AugmentationHunting'
import TextBannerHunting from '../components/servicesHunting/TextBannerHunting'
import { withNamespaces } from "react-i18next";

function servicesHunting() {
  return (
    <Layout>
        <SEO title="Services Hunting | Exsis Digital Angels" description={"Nearshore Agile Teams | Specialists in Tech Talent"} />
        <BannerServicesHunting image={imagee} TextComponent={TextBannerHunting} />
        <OurServicesHunting />
        <WhyUsHunting />
        <PreOperationStage/>
        <RequestQuoteHunting/>
        <HuntingBenefits/>
        <StandarProfilegHunting/>
        <AugmentationHunting/>
    </Layout>
  )
}

export default withNamespaces()(servicesHunting)