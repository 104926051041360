import React from "react"
import { Link } from "gatsby"
import i18n from "i18next"

function RequestQuoteHunting() {
  return (
    <>
      <div className="hidden tablet:block mb-20 mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerRequestQuote">
        <div className="flip-card-inner2 contentBannerAcademy  mt-24 mb-24">
          <div className="">
            <p className="titleBannerAcademy">
              {i18n.t("pageServiceHunting.requestQuote.title")}
            </p>
            <p className="textBannerAcademy my-4">
              {i18n.t("pageServiceHunting.requestQuote.description")}
            </p>
            <span>
              <Link to={"/contact-us"}>
                <button
                  className="mobile:mt-4 contentButtomMoreAcademy"
                  style={{ alignSelf: "center" }}
                >
                  <p className="textButtomGetTouch">
                    {i18n.t("pageServiceHunting.requestQuote.button")}
                  </p>
                </button>
              </Link>
            </span>
          </div>
        </div>
      </div>

      <div className="tablet:hidden mb-20 mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card BgBannerRequestQuoteMobile">
        <div className="flip-card-inner2   mt-24 mb-24">
          <div className="">
            <p className="titleBannerAcademy">
              {i18n.t("pageServiceHunting.requestQuote.title")}
            </p>
            <p className="textBannerAcademyMobile my-4 mx-4">
              {i18n.t("pageServiceHunting.requestQuote.description")}
            </p>
            
            <span className="w-full">
              <Link to={"/contact-us"}>
                <button
                  className="mobile:mt-4 w-full contentButtomMoreAcademy"
                  style={{ alignSelf: "center" }}
                >
                  <p className="textButtomGetTouch">
                    {i18n.t("pageServiceHunting.requestQuote.button")}
                  </p>
                </button>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestQuoteHunting
