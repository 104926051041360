import React, { useState } from "react"
import { Link } from "gatsby"
import i18n from "i18next"
import imgCarrusel4 from "../../images/servicesHunting/carrusel/image-carrusel-hunting-1.png"
import imgCarrusel from "../../images/servicesHunting/carrusel/image-carrusel-hunting-2.png"
import imgCarrusel3 from "../../images/servicesHunting/carrusel/image-carrusel-hunting-3.png"
import imgCarrusel2 from "../../images/servicesHunting/carrusel/image-carrusel-hunting-4.png"
import leftArrow from "../../images/servicesHunting/icons/leftArrow.png"
import rigthArrow from "../../images/servicesHunting/icons/rigthArrow.png"
function OurServicesHunting() {
  const images = [imgCarrusel4,imgCarrusel2,imgCarrusel3,imgCarrusel]
  const [currentIndex, setCurrentIndex] = useState(0)

  const nextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % images.length)
  }

  const prevSlide = () => {
    setCurrentIndex(
      prevIndex => (prevIndex - 1 + images.length) % images.length
    )
  }
  return (
    <section className="flex flex-col-reverse p-4 mt-12 desktop:flex desktop:flex-row desktop:gap-24 justify-center  desktop:mt-20  desktop:px-24 bigdesktop:pl-0 text-left bigdesktop:pr-0 bigdesktop:justify-around ">
      <div className="flex flex-col desktop:w-5/12">
        <h4 className="text-blue-100 font-avenir-black text-16">
          {i18n.t("pageServiceHunting.ourServices.subtitle")}
        </h4>
        <p className="font-bold mt-2 font-avenir-black text-gray-600 text-40 desktop:text-48 leading-tight">
          {i18n.t("pageServiceHunting.ourServices.title")}
        </p>
        <p className="text-20 mt-6 font-avenir-light text-gray-400">
          {i18n.t("pageServiceHunting.ourServices.text")}
        </p>
        <Link to="/contact-us">
          <button className="bg-blue-100 mt-6 w-full desktop:w-fit px-16 font-sofiaPro-bold py-3 text-center text-white rounded-lg ">
            {i18n.t("pageServiceHunting.ourServices.button")}
          </button>
        </Link>
      </div>

      <div className="flex flex-col items-center desktop:pt-2 pr-3 bigdesktop:pr-0 bigdesktop:ml-10">
        <img
          src={images[currentIndex]}
          alt="Imagen"
          className="transition-opacity duration-500"
        />
        <div className="flex gap-16 desktop:gap-10 w-full justify-center desktop:justify-end mt-4">
          <button onClick={prevSlide}>
            <img className="w-10 desktop:w-full" src={leftArrow} alt="arrowLeft" />
          </button>
          <button onClick={nextSlide}>
            <img className="w-10 desktop:w-full" src={rigthArrow} alt="arrowRigth" />
          </button>
        </div>
      </div>
    </section>
  )
}

export default OurServicesHunting
