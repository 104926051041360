import React from "react"
import imageWhyUs from "../../images/servicesHunting/HuntingWhyus.png"
import xElement from "../../images/servicesHunting/icons/xDoble.png"
import { Link } from "gatsby"
import i18n from "i18next"
import checkIcon from "../../images/servicesHunting/icons/check.png"
function WhyUsHunting() {
  const itemWhyUs = [
    {
      texto: i18n.t("pageServiceHunting.whyUsHunting.itemsHunting.item1"),
    },
    {
      texto: i18n.t("pageServiceHunting.whyUsHunting.itemsHunting.item2"),
    },
    {
      texto: i18n.t("pageServiceHunting.whyUsHunting.itemsHunting.item3"),
    },
  ]

  return (
    <section className="whyusdiv desktop:flex mb-16 pb-16 gap-24 ultradesktop:gap-10 mt-20 p-8 desktop:px-0 desktop:py-16 tablet:p-0 text-left">
      <div className="desktop:w-1/2 ultradesktop:flex flex-col ultradesktop:items-center">
        <img
          src={imageWhyUs}
          className="ultradesktop:w-9/12 bigdesktop:w-4/5 pt-10"
          alt="whyus"
        />
        <div className="flex w-full justify-center">
          <img className="w-1/3 desktop:w-fit" src={xElement} alt="xs" />
        </div>
      </div>
      <div className="flex flex-col gap-6 justify-left desktop:w-5/12">
        <h2 className="text-40 font-avenir-black text-gray-600">
          {i18n.t("pageServiceHunting.whyUsHunting.title")}
        </h2>
        <p className="text-gray-500 font-avenir-light text-20">
          {i18n.t("pageServiceHunting.whyUsHunting.description")}
        </p>

        <div className="">
          {itemWhyUs.map((item, index) => (
            <div key={index} className="flex gap-4 justify-center items-center mb-5">
              <div className="w-fit ">
                <img className="w-10" src={checkIcon} alt="check" />
              </div>
              <div className="flex-1 text-gray-500 ">
                <p className="text-20 w-11/12">{item.texto}</p>
              </div>
            </div>
          ))}
        </div>
     
        <span className="">
          <Link to="/contact-us">
            <button className="bg-blue-100 w-full desktop:w-fit text-white text-16 font-sofiaPro-bold rounded-lg px-16 py-3">
              {i18n.t("pageServiceHunting.whyUsHunting.button")}
            </button>
          </Link>
        </span>
      </div>
    </section>
  )
}

export default WhyUsHunting
