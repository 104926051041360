import React, { useState, useRef } from "react"
import i18n from "i18next"
import icon1 from "../../images/servicesHunting/icons/pre-operation/icon1.png"
import icon2 from "../../images/servicesHunting/icons/pre-operation/icon2.png"
import icon3 from "../../images/servicesHunting/icons/pre-operation/icon3.png"
import leftArrow from "../../images/servicesHunting/icons/leftArrow.png"
import rigthArrow from "../../images/servicesHunting/icons/rigthArrow.png"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function PreOperationStage() {
  const icons = [icon1, icon2, icon3]
  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef(null)

  const itemPreOperation = [
    {
      title: i18n.t("pageServiceHunting.preOperation.itemIcons.stage1.title"),
      stitle: i18n.t("pageServiceHunting.preOperation.itemIcons.stage1.stitle"),
      description: {
        desc1: i18n.t(
          "pageServiceHunting.preOperation.itemIcons.stage1.description.desc1"
        ),
        desc2: i18n.t(
          "pageServiceHunting.preOperation.itemIcons.stage1.description.desc2"
        ),
        desc3: i18n.t(
          "pageServiceHunting.preOperation.itemIcons.stage1.description.desc3"
        ),
      },
    },
    {
      title: i18n.t("pageServiceHunting.preOperation.itemIcons.stage2.title"),
      stitle: i18n.t("pageServiceHunting.preOperation.itemIcons.stage2.stitle"),
      description: {
        desc1: i18n.t(
          "pageServiceHunting.preOperation.itemIcons.stage2.description.desc1"
        ),
        desc2: i18n.t(
          "pageServiceHunting.preOperation.itemIcons.stage2.description.desc2"
        ),
        desc3: i18n.t(
          "pageServiceHunting.preOperation.itemIcons.stage2.description.desc3"
        ),
      },
    },
    {
      title: i18n.t("pageServiceHunting.preOperation.itemIcons.stage3.title"),
      stitle: i18n.t("pageServiceHunting.preOperation.itemIcons.stage3.stitle"),
      description: {
        desc1: i18n.t(
          "pageServiceHunting.preOperation.itemIcons.stage3.description.desc1"
        ),
        desc2: i18n.t(
          "pageServiceHunting.preOperation.itemIcons.stage3.description.desc2"
        ),
        desc3: i18n.t(
          "pageServiceHunting.preOperation.itemIcons.stage3.description.desc3"
        ),
      },
    },
  ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const goToSlide = index => {
    setCurrentIndex(index)
    if (carouselRef.current) {
      if (index < currentIndex) {
        carouselRef.current.slickPrev()
      } else if (index > currentIndex) {
        carouselRef.current.slickNext()
      }
    }
  }

  return (
    <section className="p-8 tablet:p-20 text-left bigdesktop:pl-32">
      <div className="flex flex-col">
        <h4 className="text-blue-100 font-avenir-black text-16">
          {i18n.t("pageServiceHunting.preOperation.subtitle")}
        </h4>
        <p className="font-bold mt-2 mb-4 font-avenir-black text-gray-600 text-48 leading-tight">
          {i18n.t("pageServiceHunting.preOperation.title")}
        </p>

        <div className="hidden desktop:flex gap-5 w-full desktop:px-12 ultradesktop:px-0">
          {itemPreOperation.map((item, index) => (
            <div
              key={index}
              className="flex-1 p-8 desktop:p-16 shadow-xl rounded-xs"
            >
              <div className="flex flex-col items-center gap-3  ">
                <img className="w-20" src={icons[index]} alt="icon" />
                <p className="text-20 bigdesktop:text-24 font-avenir-black text-gray-600">
                  {item.title}
                </p>
                <div className="text-gray-500 font-avenir-light text-14 bigdesktop:text-18 desktop:text-16 w-full flex-col justify-start">
                  <p className="">{item.stitle} </p>
                  <ul className="list-disc ml-6 mt-3">
                    <li>{item.description.desc1}</li>
                    <li>{item.description.desc2}</li>
                    <li>{item.description.desc3}</li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/** Mobile */}
        <div className="tablet:hidden">
          <Slider
            ref={carouselRef}
            {...settings}
            className="rounded-sm shadow-xl"
          >
            {itemPreOperation.map((item, index) => (
              <div key={index} className="flex-1 p-8 desktop:p-16 rounded-xs ">
                <div className="flex flex-col items-center gap-3  ">
                  <img className="w-20" src={icons[index]} alt="icon" />
                  <p className="text-20 font-avenir-black text-gray-600">
                    {item.title}
                  </p>
                  <div className="text-gray-500 font-avenir-light text-14 desktop:text-16 w-full flex-col justify-start">
                    <p className="">{item.stitle} </p>
                    <ul className="list-disc ml-6 mt-3">
                      <li>{item.description.desc1}</li>
                      <li>{item.description.desc2}</li>
                      <li>{item.description.desc3}</li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <div className="flex justify-center gap-10 my-8">
            <img
              src={leftArrow}
              alt="left"
              className="cursor-pointer"
              onClick={() => goToSlide(currentIndex - 1)}
            />
            <img
              src={rigthArrow}
              alt="rigth"
              className="cursor-pointer"
              onClick={() => goToSlide(currentIndex + 1)}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PreOperationStage
