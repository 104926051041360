import React, { useEffect, useState } from "react"
import i18n from "i18next"

function TextBannerHunting() {
  const [language, setLanguage] = useState("")
  useEffect(() => {
    setLanguage(i18n.language)
  }, [i18n.language])
  return (
    <div className="text-left pl-5 tablet:pl-20 bigdesktop:pl-24 text-white mb-32 tablet:mb-0 ">
      {language === "en" ? (
        <p className="text-20 bigdesktop:text-24 font-avenir-heavy">
          E<span className="text-blue-100">x</span>pansion Services
        </p>
      ) : (
        <p className="text-20 bigdesktop:text-24 font-avenir-heavy">
          Servicios de E<span className="text-blue-100">x</span>
          pansión
        </p>
      )}
      <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
        E<span className="text-blue-100">x</span>ceptional
        <br />
        <span className="text-relativo">IT Hunting</span>
      </p>
    </div>
  )
}

export default TextBannerHunting
