import React, { useEffect, useState } from "react"
import i18n from "i18next"
import point from "../../images/servicesHunting/icons/Elipse.png"
import huntinCircle from "../../images/servicesHunting/HuntingvsStaffHunting.png"
import staffCircle from "../../images/servicesHunting/HuntingvsStaffStaff.png"

function AugmentationHunting() {
  const [language, setLanguage] = useState("")
  useEffect(() => {
    setLanguage(i18n.language)
  }, [i18n.language])
  
  const itHunting = [
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage1.desc1"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage1.desc2"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage1.desc3"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage1.desc4"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage1.desc5"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage1.desc6"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage1.desc7"),
    },
  ]
  const staffAugmentation = [
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage2.desc1"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage2.desc2"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage2.desc3"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage2.desc4"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage2.desc5"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage2.desc6"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage2.desc7"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage2.desc8"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage2.desc9"),
    },
    {
      text: i18n.t("pageServiceHunting.augmentation.stage.stage2.desc10"),
    },
  ]

  return (
    <section className="p-4 tablet:p-20 text-left bigdesktop:px-32">
      <div className="flex flex-col">
        <h4 className="text-blue-100 font-avenir-black text-12">
          {i18n.t("pageServiceHunting.augmentation.stitle")}
        </h4>
        <h2 className="font-bold mt-2 desktop:w-1/2  bigdesktop:w-1/3 mb-4 font-avenir-black text-gray-600 text-48 leading-tight">
          {i18n.t("pageServiceHunting.augmentation.title")}
        </h2>
        <div className="tablet:flex justify-center gap-4 tablet:p-10">
          <div className="flex desktop:w-1/3  flex-col gap-3 pb-8 my-6  tablet:mb-0 items-center shadow-xl rounded-sm p-8 bigdesktop:py-12 bigdesktop:px-16">
            <img src={huntinCircle} alt="itHunting" />
            <h4 className="bigdesktop:text-24 font-avenir-black">
              {i18n.t("pageServiceHunting.augmentation.stage.stage1.title")}
            </h4>
            {itHunting.map((item, i) => (
              <div
                key={(i + 1) * 5}
                className="flex items-center self-start gap-4"
              >
                <img src={point} alt="punto" />
                <p className="text-16 bigdesktop:text-18 text-gray-500">
                  {item.text}
                </p>
              </div>
            ))}
          </div>

          <div className="flex desktop:w-1/3  flex-col gap-3 pb-8 my-6  tablet:mb-0 items-center shadow-xl rounded-sm p-8  bigdesktop:py-12 bigdesktop:px-16">
            <img src={staffCircle} alt="staff" />
            <h4 className="bigdesktop:text-24 font-avenir-black">
              {i18n.t("pageServiceHunting.augmentation.stage.stage2.title")}
            </h4>

            {staffAugmentation.map((item, i) => (
              <div
                key={(i + 1) * 7}
                className="flex items-center self-start gap-4"
              >
                <img src={point} alt="punto" />
                <p className="text-16 bigdesktop:text-18 text-gray-500">
                  {item.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AugmentationHunting
